
export   const kr = {
    login: {
        "App Store 下载":"App Store",
		"Google Play 下载":"Google Play",
        "注册":"지금 가입하세요",
        "title": "신용카드로 암호화폐를 구입해보세요!",
        title2: "XRP를 입금하고  3,500USDT 보너스를 받아보세요. <br/>최대 14,000USDT 까지 수령가능!",
        "li1": "2011년에 설립된 BTCC는 세계 최장 운영중인 거래소입니다.",
        "li1_1": "2011년에 설립된 BTCC는 세계 최장 운영중인 거래소입니다.",
        "li2": "미국 MSB, 캐나다 MSB 라이센스 획득",
        "li3": "18개 이상의 암호화폐 거래 가능",
        "li4": "9개의 법정화폐로 입금 가능",
        "li5": "1 - 150 레버리지로 거래",
        li6: "BTCC에 가입 후 7일 내에 목표금액을 입금하여 해당 보너스를 받아보세요.",
        "tab1":"이메일",
        "首页url":"https://lp.btcc.academy/ko-KR",
        "首页":"홈",
        "关于我们":"BTCC에 대해",
        "about":"ko-KR/about-kr.html",
        "about_in":"about_in-kr.html",
        "about_dm":"about_dm-kr.html",
        "about_coupon":"about_coupon-kr.html",
        "about_rebate":"about_rebate-kr.html",
        "about_lottery":"about_lottery-kr.html",
        "email": "이메일 주소를 입력하세요.",
        "code": "인증 번호를 입력하세요.",
        "Send": " 인증 번호 받기",
        "pwd": "비밀번호 6~16자리 설정",
        "tab2": "휴대폰번호",
        "mobile": "전화번호를 입력하세요.",
        "Register": "가입하기",
        "服务条款":"https://www.btcc.com/ko-KR/detail/148498.html",
        "隐私声明":"https://www.btcc.com/ko-KR/detail/148499.html",
        "风险提示":"https://www.btcc.com/ko-KR/detail/149039.html",
        "mobileTip": "정확한 전화번호를 입력하세요.",
        "emailTip": "정확한 주소를 입력하세요.",
        "codeTip": "정확한 인증 번호를 입력하세요.",
                "pwdTip": "비밀번호는 영문 대소문자 및 숫자/특수문자를 포함하여 8자 이상이어야 합니다.",
        "tips":"을 읽었으며 동의합니다.",
		"tips1":"《서비스 이용 약관》",
        "tips2":"《프라이버시 정책》",
		"tips3":"《리스크공시》" ,
		"tipslang":"https://www.btcc.com/detail/142641.html",
        registeSuccessInfo: "가입이 완료되었습니다. ",
        startTrading: "지금 거래를 시작하세요!",
        registeSuccessInfoPhone: "가입이 완료되었습니다. ",
        startTradingPhone: "지금 거래를 시작하세요!",
        注册成功:"가입 완료",
        下载应用:"앱을 다운 받고 <span>10USDT를</span> <br/>받아보세요!",
        立即下载:"다운로드"
    },
    ad_sign_up:{
        邮箱注册:"이메일로 가입",
        电子邮箱:"이메일",
        验证码:"인증번호",
        获取验证码:"인증번호 받기",
        设置密码:"비밀번호 6~16자리 설정",
        立即注册:"가입하기",
        手机注册:"이메일로 가입",
        手机号码:"전화번호",
      },

      tab:{
        限时:"한시적",
        解锁新人专享充值:"신규 사용자 전용 입금 ",
        "3500USDT赠金":"<span>3500 USDT</span> 증정금 잠금 해제 ！",
        我们推出新用户专属福利计划:"신규 가입자는 가입일로부터 7일 이내에 입금하면 3500 USDT를 증정하고, ",
        新用户自注册日起7天内完成充值即得:"최대 17,500 USDT까지 잠금해제 할 수 있는 새로운 고객 전용",
        最高可解锁:" 복지 프로그램을 선보입니다!",
        等级:"등급별",
        有效充值:"유효입금",
        累计赠金:"누적증정금(USDT)",
        XXXX已解锁XX:"XXX는XXXUSDT증정금 해제",
        立即注册:"지금 가입하기",
        "李**":"<span>진**</span>는<span>200</span>USDT증정금 해제",
        "肖**":"<span>정**</span>는<span>25</span> USDT증정금 해제",
        "张**":"<span>박**</span>는<span>50</span> USDT증정금 해제",
        "王**":"<span>재*</span>는<span>1000</span> USDT증정금 해제",
        "刘**":"<span>하**</span>는<span>100</span> USDT증정금 해제",
        "林**":"<span>변**</span>는<span>400</span> USDT증정금 해제",
        "吴**":"<span>정**</span>는<span>2500</span> USDT증정금 해제",
        "谢**":"<span>정* </span>는<span>10</span> USDT증정금 해제",
    },


    countryList:{
        "ISO_3166_716": "짐바브웨",
        "ISO_3166_074": "부베 섬",
        "ISO_3166_466": "말리",
        "other": "기타",
        "ISO_3166_072": "보츠와나",
        "ISO_3166_096": "브루나이",
        "ISO_3166_100": "불가리아",
        "ISO_3166_854": "부르키나파소",
        "ISO_3166_108": "부룬디",
        "ISO_3166_120": "카메룬",
        "ISO_3166_124": "캐나다",
        "ISO_3166_140": "중앙아프리카 공화국",
        "ISO_3166_152": "칠레",
        "ISO_3166_162": "크리스마스 섬",
        "ISO_3166_166": "코서스 제도",
        "ISO_3166_170": "콜롬비아",
        "ISO_3166_756": "스위스",
        "ISO_3166_178": "콩고",
        "ISO_3166_184": "쿡 제도",
        "ISO_3166_188": "코스타리카",
        "ISO_3166_384": "상아해안",
        "ISO_3166_192": "쿠바",
        "ISO_3166_196": "키프로스",
        "ISO_3166_203": "체코공화국",
        "ISO_3166_208": "덴마크",
        "ISO_3166_262": "지부티",
        "ISO_3166_276": "독일",
        "ISO_3166_012": "알제리",
        "ISO_3166_218": "에콰도르",
        "ISO_3166_818": "이집트",
        "ISO_3166_732": "니시사하라",
        "ISO_3166_724": "스페인",
        "ISO_3166_233": "에스토니아",
        "ISO_3166_246": "핀란드",
        "ISO_3166_250": "프랑스",
        "ISO_3166_583": "미크로네시아",
        "ISO_3166_266": "가봉",
        "ISO_3166_226": "적도 기니",
        "ISO_3166_254": "프랑스령 기아나",
        "ISO_3166_270": "감비아",
        "ISO_3166_239": "그루지야",
        "ISO_3166_288": "가나",
        "ISO_3166_292": "지브롤터",
        "ISO_3166_300": "그리스",
        "ISO_3166_304": "그린란드",
        "ISO_3166_826": "영국",
        "ISO_3166_308": "그린우드",
        "ISO_3166_312": "과들푸프",
        "ISO_3166_316": "괌",
        "ISO_3166_320": "과테말라",
        "ISO_3166_624": "기니비사우",
        "ISO_3166_328": "기아나",
        "ISO_3166_191": "크로아티아",
        "ISO_3166_332": "아이티",
        "ISO_3166_340": "온두라스",
        "ISO_3166_348": "헝가리",
        "ISO_3166_352": "아이슬란드",
        "ISO_3166_356": "인도",
        "ISO_3166_360": "인도네시아",
        "ISO_3166_364": "이란",
        "ISO_3166_368": "이라크",
        "ISO_3166_086": "영연방의 인도양 영역",
        "ISO_3166_372": "아일랜드",
        "ISO_3166_376": "이스라엘",
        "ISO_3166_380": "이탈리아",
        "ISO_3166_388": "자메이카",
        "ISO_3166_392": "일본",
        "ISO_3166_400": "요르단",
        "ISO_3166_398": "카자흐스탄",
        "ISO_3166_214": "도미니카",
        "ISO_3166_404": "켄야",
        "ISO_3166_296": "키리바시",
        "ISO_3166_408": "북한",
        "ISO_3166_410": "한국",
        "ISO_3166_116": "캄보디아",
        "ISO_3166_174": "코모로",
        "ISO_3166_414": "쿠웨이트",
        "ISO_3166_417": "키르기스스탄",
        "ISO_3166_136": "케이맨 제도",
        "ISO_3166_418": "라오스",
        "ISO_3166_144": "스리랑카",
        "ISO_3166_428": "라토비아",
        "ISO_3166_422": "레바논",
        "ISO_3166_426": "레스토",
        "ISO_3166_430": "리베리아",
        "ISO_3166_434": "리비아",
        "ISO_3166_438": "리히텐슈타인",
        "ISO_3166_440": "리투아니아",
        "ISO_3166_442": "룩셈부르크",
        "ISO_3166_450": "마다가스카르",
        "ISO_3166_454": "말라위",
        "ISO_3166_458": "말레이시아",
        "ISO_3166_462": "몰디브",
        "ISO_3166_470": "몰타",
        "ISO_3166_584": "마셜제도",
        "ISO_3166_478": "모리타니",
        "ISO_3166_480": "모리셔스",
        "ISO_3166_484": "멕시코",
        "ISO_3166_498": "몰다바",
        "ISO_3166_492": "모나코",
        "ISO_3166_496": "몽고",
        "ISO_3166_504": "모로코",
        "ISO_3166_508": "모잠비코",
        "ISO_3166_104": "미얀마",
        "ISO_3166_516": "나미비아",
        "ISO_3166_520": "나우루",
        "ISO_3166_524": "네팔",
        "ISO_3166_528": "네덜란드",
        "ISO_3166_540": "노보헤리도니아",
        "ISO_3166_554": "뉴질랜드",
        "ISO_3166_558": "니카라과",
        "ISO_3166_562": "니제르",
        "ISO_3166_566": "나이지리아",
        "ISO_3166_570": "니우에",
        "ISO_3166_578": "노르웨이",
        "ISO_3166_512": "오만",
        "ISO_3166_586": "파키스탄",
        "ISO_3166_260": "프랑스령 폴리네시아",
        "ISO_3166_585": "팔라우",
        "ISO_3166_591": "파나마",
        "ISO_3166_598": "파푸아뉴이소네아",
        "ISO_3166_600": "파라과이",
        "ISO_3166_604": "페루",
        "ISO_3166_608": "필리핀",
        "ISO_3166_612": "피트 케인 제도",
        "ISO_3166_616": "폴란드",
        "ISO_3166_620": "포루투갈",
        "ISO_3166_630": "푸에르토리코(미)",
        "ISO_3166_634": "카타르",
        "ISO_3166_642": "루마니아",
        "ISO_3166_643": "러시아 연방",
        "ISO_3166_646": "르완다",
        "ISO_3166_222": "엘 살바도르",
        "ISO_3166_654": "세인트헬레나",
        "ISO_3166_674": "산마리노",
        "ISO_3166_678": "상투메 프린시페",
        "ISO_3166_682": "사우디아라비아",
        "ISO_3166_686": "세네갈",
        "ISO_3166_690": "세이셸",
        "ISO_3166_694": "시에라리온",
        "ISO_3166_702": "싱가포르",
        "ISO_3166_703": "슬로바키아",
        "ISO_3166_705": "슬로베니아",
        "ISO_3166_090": "솔로몬 제도",
        "ISO_3166_706": "소말리아",
        "ISO_3166_736": "수단",
        "ISO_3166_740": "수리남",
        "ISO_3166_748": "스와질란드",
        "ISO_3166_752": "스웨덴",
        "ISO_3166_760": "시리아",
        "ISO_3166_810": "소련(전)",
        "ISO_3166_148": "차드",
        "ISO_3166_762": "타지키스탄",
        "ISO_3166_834": "탄자니아",
        "ISO_3166_764": "태국",
        "ISO_3166_768": "토고",
        "ISO_3166_776": "토가",
        "ISO_3166_780": "트리니다드 토바고",
        "ISO_3166_788": "튀니지",
        "ISO_3166_792": "터키",
        "ISO_3166_626": "동제",
        "ISO_3166_795": "투르크메니스탄",
        "ISO_3166_798": "투발루",
        "ISO_3166_800": "우간다",
        "ISO_3166_804": "우크라이나",
        "ISO_3166_840": "미국",
        "ISO_3166_858": "우르과이",
        "ISO_3166_860": "우즈베키스탄",
        "ISO_3166_336": "바티칸(로마교회)",
        "ISO_3166_862": "베네수엘라",
        "ISO_3166_704": "베트남",
        "ISO_3166_882": "니시사모야",
        "ISO_3166_887": "예멘",
        "ISO_3166_891": "유고슬라비아",
        "ISO_3166_710": "남아프리카 공화국",
        "ISO_3166_180": "세이",
        "ISO_3166_894": "잠비아",
        "ISO_3166_156": "중국",
        "ISO_3166_158": "대만",
        "ISO_3166_446": "중국 마카오",
        "ISO_3166_344": "중국 홍콩",
        "ISO_3166_004": "아프가니스탄",
        "ISO_3166_008": "알바니아",
        "ISO_3166_020": "안도라",
        "ISO_3166_024": "앙골라",
        "ISO_3166_010": "남극",
        "ISO_3166_028": "앤티가바부다",
        "ISO_3166_032": "아르헨티나",
        "ISO_3166_051": "아르메니아",
        "ISO_3166_533": "아루바",
        "ISO_3166_036": "호주",
        "ISO_3166_040": "오스트리아",
        "ISO_3166_031": "아제르바이잔",
        "ISO_3166_784": "아랍 에미리트",
        "ISO_3166_044": "바하마",
        "ISO_3166_048": "바레인",
        "ISO_3166_050": "벵골",
        "ISO_3166_052": "바베이도스",
        "ISO_3166_112": "벨라루스",
        "ISO_3166_084": "벨리즈",
        "ISO_3166_056": "벨기에",
        "ISO_3166_204": "베냉",
        "ISO_3166_060": "버뮤다",
        "ISO_3166_064": "부탄",
        "ISO_3166_068": "볼리비아",
        "ISO_3166_070": "보스니아 헤르체고비나",
        "ISO_3166_076": "브라질",
        "ISO_3166_772": "토켈라우",
        "ISO_3166_324": "기니",
        "ISO_3166_231": "에티오피아",
        "ISO_3166_242": "피지",
        "ISO_3166_238": "포클랜드 제도",
    },
    "已有账户？":"이미 계정이 있으신가요? ", 
      "立即登录":"지금 로그인하세요.",
      "立即注册":"지금 가입하세요",
      "发送验证码成功":"인증번호 발송 완료!이메일로 인증번호가 도착하지 않는 경우 스팸 메일 폴더 확인을 부탁드립니다.",
      search: "검색",
    
}
