import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './globals.scss';
import Ruoter from './ruoter';
import "./lang/i18n.js";
 import { hydrate, render } from "react-dom";


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Ruoter />
//   </React.StrictMode>
// );


const App = () => {
  useEffect(() => {
    document.dispatchEvent(new Event('render-event'));
  }, []);

  return (
    <React.StrictMode>
      <Ruoter />
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));


// useEffect(() => {
//   document.dispatchEvent(new Event('render-event'));
// }, []);

// ReactDOM.render(
//   <React.StrictMode>
//     <Ruoter />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <React.StrictMode>
//      <Ruoter />
//     </React.StrictMode>,
//     rootElement
//   );
// } else {
//   render(
//     <React.StrictMode>
//      <Ruoter />
//     </React.StrictMode>,
//     rootElement
//   );
// }




